<template>
	<div class="auth-form">
		<h1 class="h1 mb-24 text-center">Авторизация</h1>

		<form
			class="auth-form__form form form--card form--shadow form--sm"
			@submit.prevent="onSubmit">
			<div
				class="form__error"
				:class="{ 'form__error--form': authErrorText }">
				<svg class="icon form__error-icon">
					<use xlink:href="@/assets/sprites/sprite.svg#attention"></use>
				</svg>

				<span class="form__error-text">{{ authErrorText }}</span>
			</div>

			<div
				class="form__group"
				:class="{ 'form__group--error': v$.login.$invalid && v$.login.$dirty }">
				<div class="form__group-header">
					<label
						for=""
						class="form__label"
						>Логин</label
					>
				</div>

				<input
					v-model="loginData.login"
					type="email"
					class="form__input"
					placeholder="Введите логин" />

				<div class="form__error">
					<svg class="icon form__error-icon">
						<use xlink:href="@/assets/sprites/sprite.svg#attention"></use>
					</svg>

					<span class="form__error-text">Неверно введен адрес эл. почты</span>
				</div>
			</div>

			<div
				class="form__group auth-form__group"
				:class="{ 'form__group--error': v$.password.$invalid && v$.password.$dirty }">
				<div class="form__group-header">
					<label
						for=""
						class="form__label"
						>Пароль<span class="form__star">*</span></label
					>

					<NuxtLink
						class="link link--purple p6"
						to="/login/forgot-password"
						title="Восстановить пароль">
						Забыли пароль?
					</NuxtLink>
				</div>

				<input
					v-model="loginData.password"
					type="password"
					class="form__input"
					placeholder="Введите пароль" />
			</div>

			<div class="form__control mb-24">
				<button
					v-if="!fetchAuth"
					type="submit"
					class="form__button button button--sm button--purple"
					:disabled="v$.$invalid">
					Авторизоваться
				</button>

				<Loader
					v-else
					class="m-both-auto" />
			</div>

			<div class="p6 text-center">
				Еще нет аккаунта?
				<NuxtLink
					class="link link--purple"
					to="/login/registration"
					title="Зарегистрироваться"
					>Регистрация
				</NuxtLink>
			</div>
		</form>
	</div>
</template>

<script setup>
	import { required, email } from "@vuelidate/validators";
	import { useVuelidate } from "@vuelidate/core";

	const { signIn } = useAuth();
	const loginData = reactive({
		login: "",
		password: "",
	});

	const rules = computed(() => ({
		login: { required, email, $autoDirty: true },
		password: { required, $autoDirty: true },
	}));

	const v$ = useVuelidate(rules, loginData);

	const authErrorText = useState("auth-error-text", () => "");
	authErrorText.value = "";
	const fetchAuth = useState("fetch-auth", () => false);

	const onSubmit = async () => {
		if (!v$.value.$invalid) {
			fetchAuth.value = true;
			authErrorText.value = "";

			const res = await signIn("credentials", {
				callbackUrl: "/",
				redirect: false,
				username: loginData.login,
				password: loginData.password,
			});

			if (res.error) {
				authErrorText.value = res.error.includes("401") ? "Неверный логин или пароль" : res.error;
			} else {
				await useRouter().push("/");
			}

			fetchAuth.value = false;
		}
	};
</script>

<style lang="scss">
	@import "auth-form";
</style>
