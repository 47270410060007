<template>
	<auth-form></auth-form>
</template>

<script setup>
	import { useHead } from "#app";

	definePageMeta({
		layout: "auth",
		auth: {
			unauthenticatedOnly: true,
			navigateAuthenticatedTo: "/",
		},
	});
	useHead({
		title: "Авторизация",
	});
</script>
